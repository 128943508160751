import agent from '../../api/agent';

export default {
  fetchLanguages: async ({ commit }) => {
    try {
      commit('SET_LANGUAGES', await agent.languages.all());
    } catch (error) {
      console.error(error);
    }
  },

  selectLanguage: ({ commit }, languageId) => {
    commit('SET_SELECTED_LANGUAGE', languageId);
  },
};
